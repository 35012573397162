html,
body {
    padding: 0;
    margin: 0;
    font-family: "GrotesqueMT";
}

.map-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.leaflet-div-icon {
    border: 0;
    background: none;
}

.leaflet-container {
    font-family: inherit;
}
